import FONT_FAMILIES from './fontFamily';
import { BTN_STYLES, BTN_COLORS } from './designs';
import { COLOR_PRIMARY } from './theme';

const TEXT_COLOR = COLOR_PRIMARY;

export const LOGO_SIZES = [32, 64, 96, 128, 192, 265, 512];

export const DEFAULT_WORKING_HOURS = { from: { hour: 9 }, to: { hour: 17 } };
export const PRIMARY_BTN_VALUE = 'yes';
export const SECONDARY_BTN_VALUE = 'no';
export enum VERTICAL_POSITION  {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
};

export enum HORIZONTAL_POSITION  {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
};

export enum COVER_SETTINGS {
  COVER = 'cover',
  CONTAIN = 'contain',
};

export const DEFAULT_STORY = {
  NAME: 'Default name',
  EXAMPLE_TEXT: 'Your Text Here',
  LOGO_SIZE_PERCENTS: 2,
  FONT_FAMILY: FONT_FAMILIES[0].value,
  BACKGROUND: {
    VERTICAL_POSITION: VERTICAL_POSITION.CENTER,
    HORIZONTAL_POSITION: HORIZONTAL_POSITION.CENTER,
    CONTAINER_FIT: COVER_SETTINGS.COVER,
  },
  BTN: {
    BORDER_RADIUS: BTN_STYLES[0].value,
    COLOR_PRIMARY: BTN_COLORS.PRIMARY,
    COLOR_SECONDARY: BTN_COLORS.SECONDARY,
  },
  OVERLAY: {
    COLOR: '#000000',
    OPACITY: 0.5,
  },
  STEP_WELCOME: {
    LOGO_URL: null as null | string,
    LOGO_NAME: null as null | string,
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Your Text Here',
    BTN_TEXT: 'Get Started',
  },
  STEP_OPTION_PICKER_1: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Your Text Here',
    BTN_PRIMARY: {
      text: 'Yes',
      isExternal: false,
      url: null as null | string,
    },
    BTN_SECONDARY: {
      text: 'No',
      isExternal: false,
      url: null as null | string,
    },
  },
  STEP_OPTION_PICKER_2: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Your Text Here',
    BTN_PRIMARY: {
      text: 'Yes',
      isExternal: false,
      url: null as null | string,
    },
    BTN_SECONDARY: {
      text: 'No',
      isExternal: false,
      url: null as null | string,
    },
    BTN_PRIMARY_TEXT: 'Yes',
    BTN_SECONDARY_TEXT: 'No',
  },
  STEP_CONTACT_FORM: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Your contact info here',
    BTN_TEXT: 'Continue',
    COMMENT_TEXT: 'Any Comments?',
    COMMENT_IS_ENABLED: true,
    CONTACT_LIST_ID: null as null | string,
  },
  STEP_STORE: {
    STORE_NAME: 'Store Name',
    STORE_NAME_TEXT_COLOR: '#ffffff',
    HEADER_BG_COLOR: '#d3d3d3',
    COLUMNS_NUMBER: '4',
    ROWS_NUMBER: '3',
    DETAILS_BUTTON_COLOR: '#2699FB',
    STORE_NAME_SIZE_PERCENTS: 50,
    LOGO_POSITION: 'left',
    LOGO_NAME: 'Default',
  },
  STEP_SCHEDULE: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Schedule a demo',
    BTN_TEXT: 'Submit',
    WORKING_HOURS: {
      1: DEFAULT_WORKING_HOURS,
      2: DEFAULT_WORKING_HOURS,
      3: DEFAULT_WORKING_HOURS,
      4: DEFAULT_WORKING_HOURS,
      5: DEFAULT_WORKING_HOURS,
      6: undefined as typeof DEFAULT_WORKING_HOURS | undefined,
      0: undefined as typeof DEFAULT_WORKING_HOURS | undefined,
    },
  },
  STEP_THANK_YOU: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Thank You',
    BODY_TEXT:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel molestie lorem. Donec ullamcorper a lorem non ullamcorper.',
  },
  STEP_OPTION_SELECTOR: {
    TEXT_COLOR: TEXT_COLOR,
    HEADLINE_TEXT: 'Select Your Option',
    BTN_PRIMARY_TEXT: 'Yes',
    BTN_SECONDARY_TEXT: 'No',
    BODY_TEXT:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vel molestie lorem.',
    OPTIONS: [
      {
        label: 'Lorem ipsum dolor sit amet',
        value: '5.99$',
      },
      {
        label: 'Nature is so fantastic, enjoy it',
        value: 'Free',
      },
    ],
  },
  META: {
    PAGE_NAME: 'Your Page Name Here',
    DESCRIPTION:
      'Nature is so fantastic, enjoy it. Let it make you happy. Isn’t it great to do something you can’t fail at?',
  },
};

