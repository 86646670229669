import { isProdEnv } from "app-config";

export const TEMPLATE_TYPE = {
  CUSTOM: 'CUSTOM',
  PREDEFINED: 'PREDEFINED',
};

export const getUnlayerConfig = () => {
  if (isProdEnv()) {
    return {
      PROJECT_ID: '2591',
      TEMPLATE: {
        BLANK: '7028',
        SIMPLE_CTA: '7023',
        BASIC_TEXT: '7022',
      },
      TEMPLATE_TYPE,
    };
  }

  return {
    PROJECT_ID: '8382',
    TEMPLATE: {
      BLANK: '133193',
      SIMPLE_CTA: '133185',
      BASIC_TEXT: '133187',
    },
    TEMPLATE_TYPE,
  };
}
