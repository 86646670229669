import React from 'react';
import classNames from 'classnames';
import { SelectWithLoader } from '../SelectWithLoader';
import { generateSelectOptionsByName } from 'utils/common';
import { useStories } from 'hooks';

export const InputStoryList = ({ id, className = null, required = false, value, onChange }) => {
  const { isLoading, stories } = useStories();

  const handleStorySelected = (selectedId) => {
    if (!isLoading && stories.length) {
      const selectedStory = stories.find((item) => String(item.id) === selectedId);
      onChange(selectedStory);
    }
  };

  const classNameProp = classNames(className, 'input-media-wrapper margin-right--sm');

  return (
    <SelectWithLoader
      id={id}
      includeEmpty
      className={classNameProp}
      isLoading={isLoading}
      disabled={isLoading}
      options={generateSelectOptionsByName(stories)}
      required={required}
      value={value}
      onChange={handleStorySelected}
    />
  );
};
