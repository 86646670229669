import Storage from '@aws-amplify/storage';
import { postCall, deleteCall, getCall } from './service';
import {
  isVideo, getImageThumbnail, IMAGE_SIZES
} from 'utils/media';
import { IMedia, IOwnMedia } from 'models';

export function saveMediaFile(mediaItem: any) {
  return postCall('/api/media/save', mediaItem);
}

export function updateMediaFile({ userId, fileId, name, altText }: {
  userId: IOwnMedia["userId"];
  fileId: IOwnMedia["id"];
  name: IOwnMedia["name"];
  altText?: IOwnMedia["altText"];
}) {
  return postCall('/api/media/updateMediaFile', { userId, fileId, name, altText });
}

export function getUserMediaFiles({ userId, offset, limit, filter = '' }: {
  userId: IOwnMedia["userId"];
  offset?: number;
  limit?: number;
  filter: string;
}) {
  return getCall<{
    files: IMedia[];
    total: number;
  }>('/api/media/getUserMediaFiles', { userId, offset, limit, filter });
}


export function deleteMediaFileFromDB({ userId, file }: {
  userId: IOwnMedia["userId"];
  file: IMedia
}) {
  return deleteCall('/api/media/deleteMediaFileFromDB', {
    userId,
    fileId: file.id,
  });
}

export function deleteMediaFileFromS3(file: IMedia) {
  const fileKey = file.s3Key;
  const filesToRemove = isVideo(file.contentType)
    ? []
    : Object.values(IMAGE_SIZES)
      .map((size) => getImageThumbnail(fileKey, size) as string);

  filesToRemove.push(fileKey);

  return Promise.all(
    filesToRemove.map((fileKey) => Storage.remove(fileKey))
  );
}
