import React from 'react';
import Img from 'react-image';
import styled from 'styled-components';
import { Spinner } from '../Spinner';

const Wrapper = styled.div`
  position: relative;
  width: 50px;
`;


export const LazyImage = ({
  src,
  style = {},
  className = '',
  alt = '',
}: {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  alt?: string;
}) => {
  return (
    <Img
      style={style}
      className={className}
      src={src}
      alt={alt}
      loader={
        <Wrapper>
          <Spinner className={className || ''} />
        </Wrapper>
      }
    />
  );
};
