import API from '@aws-amplify/api';
import { API_NAME } from 'app-config';

export type TReturnMakePromiseCancelable = {
  promise: Promise<any>;
  cancel: () => void;
};

export function makePromiseCancelable(promise: Promise<any>): TReturnMakePromiseCancelable {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (response: any) => (hasCanceled ? reject({ isCanceled: true }) : resolve(response)),
      (error: any) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      hasCanceled = true;
    },
  };
}

export function getCall<T = any>(path: string, params?: any) {
  const data = params
    ? {
        queryStringParameters: params,
      }
    : undefined;

  return API.get(API_NAME, path, data)
    .then((res) => prepareSuccessResponse(res))
    .catch(prepareErrorResponse) as Promise<T>;
}

export function postCall(path: string, body?: any) {
  const data = body
    ? {
        body,
      }
    : undefined;

  return API.post(API_NAME, path, data).then(prepareSuccessResponse).catch(prepareErrorResponse);
}

export function putCall(path: string, body?: any) {
  const data = body
    ? {
        body,
      }
    : undefined;

  return API.put(API_NAME, path, data).then(prepareSuccessResponse).catch(prepareErrorResponse);
}

export function deleteCall(path: string, body?: any) {
  const data = body
    ? {
        body,
      }
    : undefined;

  return API.del(API_NAME, path, data).then(prepareSuccessResponse).catch(prepareErrorResponse);
}

export function prepareSuccessResponse(response: any) {
  if (response?.success) {
    return response.body;
  }

  return undefined;
}

export function prepareErrorResponse(error: any) {
  // https://github.com/axios/axios/issues/960
  return Promise.reject(error?.response?.data?.error);
}
