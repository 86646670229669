import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import './styles.scss';

export type TSelectOption = {
  value: string | number;
  label: string;
}

export type TSelectProps<T> = {
  options?: T[];
  includeEmpty?: boolean;
  hideArrow?: boolean;
  value: string,
  onChange: (value: string) => void;
} & Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "onChange" | "value">;


export const Select = <T extends TSelectOption>({
  className,
  hideArrow,   includeEmpty = false,
  options = [],
  value,
  onChange,
  ...props
}: TSelectProps<T>) => {
  const selectField = React.useRef(null);
  const compClass = classNames(className, 'custom-select', {
    'custom-select--no-arrow': hideArrow,
  });

  // useEffect(() => {
  //   console.log('should be ')
  //   if (options.length === 1 && typeof value === 'undefined') {
  //     console.log('should be selected')
  //     onChange(options[0]?.value);
  //   }
  // }, [options])

  return (
    <select
      ref={selectField}
      className={compClass}
      value={value}
      {...props}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
    >
      {includeEmpty && <option value="">Select</option>}

      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
