import { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useUserId } from './useUserId';
import { ISortingOption } from 'models/ISorting';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import * as ProductService from 'services/products';
import * as StoreService from 'services/v2/store';
import { IStore, IProduct } from 'models';

export const useStoreProducts = ({
  initPage = 0,
  initPageSize = 10,
  initSortingKey = '',
  iStore,
}: {
  initPage: number;
  initPageSize: number;
  initSortingKey?: string;
  iStore: number;
}) => {
  const isDetached = useRef(false);
  const dispatch = useDispatch();
  const iUser = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [sortingOptions, setSortingOptions] = useState<ISortingOption>({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [total, setTotal] = useState<number>(null);

  const loadProducts = useCallback(async () => {
    if (isDetached.current) return;

    setIsLoading(true);

    try {
      const result = await ProductService.getProductListWithStore({
        iUser,
        iStore,
        sortingKey: sortingOptions.sortingKey,
        direction: sortingOptions.direction,
        pageNumber,
        pageSize,
      });

      setProducts(result.items);
      setTotal(result.total);
    }
    catch(e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    }
    finally {
      setIsLoading(false);
    }
  }, [iUser, iStore, pageSize, pageNumber, sortingOptions, dispatch]);

  useEffect(() => {
    loadProducts();
  }, [iUser, pageSize, pageNumber, sortingOptions, loadProducts]);

  const addRemoveProductToStore = useCallback(async (product: IProduct) => {
    setIsLoading(true);

    try {
      const productInStore = product.stores.some((store: IStore) => store.iStore === iStore);

      if (productInStore) {
        await StoreService.deleteProductFromStore({ iProduct: product.iProduct, iUser, iStore });
      }
      else {
        await StoreService.addProductToStore({ iProduct: product.iProduct, iUser, iStore });
      }

      loadProducts();
    }
    catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    }
    finally {
      setIsLoading(false);
    }

  }, [iStore, iUser, dispatch, loadProducts]);

  return {
    isLoading,
    products,
    total,
    sortingOptions,
    pageNumber,
    pageSize,
    setPageSize,
    setPageNumber,
    setSortingOptions,
    addRemoveProductToStore,
  };
}