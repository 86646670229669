import React from 'react';
import classNames from 'classnames';
import { SelectWithLoader, ButtonUploadMedia } from '../';
import { getUserContactLists } from 'services/contacts';
import { SignedInUserContext } from 'utils/context';
import { generateSelectOptionsByName } from 'utils/common';
import { getUserId } from 'utils/user';
import ContactListModal from './ContactListModal';
// styles come from InputMedia components

class InputContactList extends React.Component {
  state = {
    contactLists: undefined,
    showModal: false,
    isLoadingLists: false,
  };

  componentDidMount() {
    this.setState({ isLoadingLists: true });
    getUserContactLists(this.props.userId)
      .then((contactLists) => this.setState({ contactLists }))
      .catch((error) => console.error(error))
      .finally(() => this.setState({ isLoadingLists: false }));
  }

  handleListSelect = (value) => {
    const list = this.state.contactLists || [];

    if (!this.state.isLoadingLists && list.length) {
      const selectedList = list.find((item) => String(item.id) === value);

      this.props.onChange(selectedList);
    }
  };

  handleModalSave = (selectedList, isNewlyCreatedList) => {
    // if user creates a new list on the modal,
    // this.state.contactLists will not have it.
    // Need to add it to the this.state.contactLists
    const { contactLists } = this.state;

    if (isNewlyCreatedList) {
      this.setState({ contactLists: [selectedList, ...contactLists] });
    }

    this.setState({ showModal: false }, () => {
      this.props.onChange(selectedList);
    });
  };

  render() {
    const className = classNames(this.props.className, 'input-media-wrapper');
    const selectClassName = classNames({ 'margin-right--sm': !this.props.hideUploadButton });

    return (
      <div className={className}>
        <SelectWithLoader
          includeEmpty
          className={selectClassName}
          isLoading={this.state.isLoadingLists}
          disabled={this.state.isLoadingLists}
          options={generateSelectOptionsByName(this.state.contactLists)}
          required={this.props.required}
          value={this.props.value}
          onChange={this.handleListSelect}
        />

        {!this.props.hideUploadButton && (
          <ButtonUploadMedia
            iconOnly
            isPrimary
            ariaLabel="Upload Media"
            onClick={() =>
              this.setState((prevState) => ({
                showModal: !prevState.showModal,
              }))
            }
          />
        )}

        {this.state.showModal && (
          <ContactListModal
            userId={this.props.userId}
            contactLists={this.state.contactLists}
            onSave={this.handleModalSave}
            onClose={() => this.setState({ showModal: false })}
          />
        )}
      </div>
    );
  }
}

export default (props) => (
  <SignedInUserContext.Consumer>
    {(user) => <InputContactList {...props} userId={getUserId(user)} />}
  </SignedInUserContext.Consumer>
);
