import { useQuery, useMutation, useQueryClient } from "react-query";
import Storage from '@aws-amplify/storage';

import { isVideo, getImageThumbnail, IMAGE_SIZES, getImagePath } from 'utils/media';
import { delay } from 'utils/common';
import { getFileKey } from 'utils/media';
import {
  getUserMediaFiles,
  updateMediaFile,
  deleteMediaFileFromDB,
  deleteMediaFileFromS3,
  saveMediaFile,
} from 'services/media';
import { getOffsetLimitByPage } from 'utils/common';
import { DEFAULT_REQUEST_ITEMS } from "constants/app";
import { IMedia } from "models";

export const useMediaFiles = ({userId, currentPage = 0, pageSize = DEFAULT_REQUEST_ITEMS, filter = ''}:
{
  userId: string;
  currentPage?: number;
  pageSize?: number;
  filter?: string;
}) => {
    const queryClient = useQueryClient();

  const { status, data, error, isFetching } = useQuery(['media', currentPage, pageSize, filter], async () => {

    const data = await getUserMediaFiles({
      userId,
      ...getOffsetLimitByPage(pageSize, currentPage),
      filter,
    });
    const files = data?.files && Array.isArray(data.files)
    ? data.files.map((file: IMedia) => ({
        ...file,
        thumbUrl: isVideo(file.contentType)
          ? file.url
          : getImageThumbnail(file.url, IMAGE_SIZES.M),
      }))
    : [];

    return {
      files,
      total: data.total
    };
  }, {refetchOnWindowFocus: false});

  const updateMutation = useMutation((file: IMedia) => {
    return updateMediaFile({userId, fileId: file.id, name: file.name,altText: file.altText })
  }, {
    onSuccess: () => queryClient.invalidateQueries('media'),
  })

  const deleteMutation = useMutation((file: IMedia) => {
    return  deleteMediaFileFromDB({ userId, file })
    .then(() => deleteMediaFileFromS3(file))
  }, {
    onSuccess: () => queryClient.invalidateQueries('media'),
  })

  const uploadMutation = useMutation((file: File) => {
    const { type: contentType, name } = file;
    const s3Key = getFileKey(userId, name);

    return  Storage.put(s3Key, file, { contentType })
        .then((response: any) => {
          const mediaFile = {
            name,
            contentType,
            altText: '',
            url: getImagePath(response.key),
            s3Key: response.key,
            userId: userId,
          };

          return saveMediaFile(mediaFile);
        })
        .then(() => delay(3000))
  }, {
    onSuccess: () => queryClient.invalidateQueries('media'),
  });

  const updateFile = (file: IMedia)  =>  updateMutation.mutate(file);
  const deleteFile = (file: IMedia)  => deleteMutation.mutate(file);
  const uploadFile = (file: File) => uploadMutation.mutate(file);

  return {
    isUploading: uploadMutation.isLoading,
    isLoading: isFetching || updateMutation.isLoading || deleteMutation.isLoading || uploadMutation.isLoading,
    error,
    status,
    files: data?.files || [],
    totalFiles: data?.total || 0,

    updateFile,
    deleteFile,
    uploadFile,
  }
}
