import './styles.scss';
import React, { useState } from 'react';
import { Icon } from '@blueprintjs/core';
import classNames from 'classnames';

import { ISortingOption } from 'models';

const DEFAULT_SORT_DIRECTION = true;

type TColumn = string | {
  key: string;
  name: string;
  className?: string;
}
export interface ITableHeaderProps {
  columns: TColumn[],
  sortingOptions?: ISortingOption;
  onSortChange?: (props: ISortingOption) => void,
}

export const TableHeader = ({ columns = [], sortingOptions = null, onSortChange }: ITableHeaderProps) => {
  const isSortable = sortingOptions && onSortChange;

  const [sortingKey, updateSortingKey] = useState(isSortable && sortingOptions.sortingKey);
  const [isAscending, updateSortingDirection] = useState(
    isSortable && sortingOptions.direction === 'asc'
  );

  const handleSorting = (col: TColumn) => {
    if (!isSortable || typeof col === 'string' || !col.key) return;

    let newDirection = !isAscending;

    if (sortingKey !== col.key) {
      updateSortingKey(col.key);
      newDirection = DEFAULT_SORT_DIRECTION;
    }

    updateSortingDirection(newDirection);

    onSortChange &&
      onSortChange({
        sortingKey: col.key,
        direction: newDirection ? 'asc' : 'desc',
      });
  };

  return (
    <thead>
      <tr>
        {columns.map((col, indx) => {
          if (typeof col === 'string') {
            return <th key={col}>{col}</th>;
          } else {
            return (
              <th
                className={classNames(col.className, `header-${col.key}`, {
                  'header-sortable': isSortable && col.key,
                })}
                key={`${col.key}${indx}`}
                onClick={() => handleSorting(col)}
              >
                {col.name}
                {isSortable && sortingKey === col.key && col.key && (
                  <Icon icon={isAscending ? 'caret-up' : 'caret-down'} />
                )}
              </th>
            );
          }
        })}
      </tr>
    </thead>
  );
};
