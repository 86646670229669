import { useUserId } from 'hooks';
import { useCallback, useState, useEffect } from 'react';
import { getContactsByStoryId, getContactsByUserId } from 'services/clientSession';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { ISortingOption } from 'models/ISorting';
import { DateRange } from '@blueprintjs/datetime/lib/esm/common/dateRange';

export const useContactsReport = ({
  initPage = 0,
  initPageSize = 10,
  initSelectedStoryId = undefined,
  initSortingKey = '',
}) => {
  const dispatch = useDispatch();

  const userId = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [filter, setFilter] = useState<{ selectedStoryId: string, dateRange: DateRange }>({
    selectedStoryId: initSelectedStoryId,
    dateRange: undefined,
  });
  const [sortingOptions, setSortingOptions] = useState<ISortingOption>({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState(null);
  const [total, setTotal] = useState<number>(null);

  const loadContacts = useCallback(async () => {
    if (!filter.selectedStoryId) return;

    setIsLoading(true);

    try {
      const res =
        filter.selectedStoryId === 'all'
          ? await getContactsByUserId({
              userId: userId,
              dateRange: filter.dateRange,
              direction: sortingOptions.direction,
              sortingKey: sortingOptions.sortingKey,
              pageNumber: pageNumber,
              pageSize: pageSize,
            })
          : await getContactsByStoryId({
              storyId: filter.selectedStoryId,
              dateRange: filter.dateRange,
              direction: sortingOptions.direction,
              sortingKey: sortingOptions.sortingKey,
              pageNumber: pageNumber,
              pageSize: pageSize,
            });

      setContacts(res.items);
      setTotal(res.total);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setIsLoading(false);
    }
  }, [userId, pageSize, pageNumber, filter, sortingOptions, dispatch]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts, userId, pageSize, pageNumber, filter, sortingOptions]);

  return {
    isLoading,
    contacts,
    total,
    filter,
    sortingOptions,
    pageNumber,
    pageSize,
    setPageNumber,
    setPageSize,
    setFilter,
    setSortingOptions,
  };
};
