export const SITE_URL = 'https://story.panels.ai';
export const PROMO_CODE = 'PROMO_CODE';


export enum LOADING_STATE {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  FULFILLED = "FULFILLED",
  REJECTED = "REJECTED"
};

export const DEFAULT_REQUEST_ITEMS = 9;