import { useCallback, useState, useEffect, useRef } from 'react';
import {
  getViewsByStoryId,
  getViewsByCampaignId,
  getStatsByStoryId,
  getStatsByCampaignId,
} from 'services/clientSession';
import { useDispatch } from 'react-redux';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { useUserId } from 'hooks';
import { getCampaignsByStoryId } from 'services/campaign';
import { makePromiseCancelable, TReturnMakePromiseCancelable } from 'services/service';
import { DateRange as TDateRange } from '@blueprintjs/datetime/lib/esm/common/dateRange';
import { IStory } from 'models/IStory';
import { ICampaign, ICampaignViews } from 'models/ICampaign';
import { ISortingOption } from 'models/ISorting';

export const useViewsReport = ({
  initPage = 0,
  initPageSize = 10,
  initSelectedStoryId = undefined,
  initSelectedCampaignId = undefined,
  initSortingKey = '',
}) => {
  const dispatch = useDispatch();
  let getCampaignsByStoryIdCancelable = useRef<null | TReturnMakePromiseCancelable>(null);

  const userId = useUserId();
  const [pageNumber, setPageNumber] = useState(initPage);
  const [pageSize, setPageSize] = useState(initPageSize);
  const [filter, setFilter] = useState<{
    selectedStoryId?: IStory["id"];
    selectedCampaignId?: ICampaign["id"];
    dateRange?: TDateRange;
  }>({
    selectedStoryId: initSelectedStoryId,
    selectedCampaignId: initSelectedCampaignId,
  });
  const [sortingOptions, setSortingOptions] = useState<ISortingOption>({
    direction: 'asc',
    sortingKey: initSortingKey,
  });

  const [isLoadingViewsItems, setIsLoadingViewsItems] = useState(false);
  const [viewsItems, setViewsItems] = useState<ICampaignViews[]>(null);
  const [total, setTotal] = useState(null);

  const [campaigns, setCampaigns] = useState<ICampaign[]>(null);
  const [isLoadingCampaigns, setIsLoadingCampaigns] = useState(false);

  const [sessionsStats, setSessionStats] = useState(null);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const loadCampaigns = useCallback(async () => {
    setIsLoadingCampaigns(true);

    try {
     getCampaignsByStoryIdCancelable.current = makePromiseCancelable(getCampaignsByStoryId({
        storyId: filter.selectedStoryId,
        userId,
        types: ['sms', 'email'],
      }));

      const promiseRes = await getCampaignsByStoryIdCancelable.current;
      const result = await promiseRes.promise;

      setCampaigns(result.items);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setIsLoadingCampaigns(false);
    }
  }, [userId, filter.selectedStoryId, dispatch]);

  useEffect(() => {

    if (filter.selectedStoryId) {
      loadCampaigns();
    }

    return () => {
      if (getCampaignsByStoryIdCancelable.current?.cancel) {
        getCampaignsByStoryIdCancelable.current.cancel()
      }
    };
  }, [filter.selectedStoryId, loadCampaigns]);

  const loadViewsItems = useCallback(async () => {
    if (!filter.selectedStoryId) return;

    setIsLoadingViewsItems(true);

    try {
      const res = filter.selectedCampaignId
        ? await getViewsByCampaignId({
            storyId: filter.selectedStoryId,
            campaignId: filter.selectedCampaignId,
            dateRange: filter.dateRange,
            direction: sortingOptions.direction,
            sortingKey: sortingOptions.sortingKey,
            pageNumber: pageNumber,
            pageSize: pageSize,
          })
        : await getViewsByStoryId({
            storyId: filter.selectedStoryId,
            dateRange: filter.dateRange,
            direction: sortingOptions.direction,
            sortingKey: sortingOptions.sortingKey,
            pageNumber: pageNumber,
            pageSize: pageSize,
          });

      setViewsItems(res?.items);
      setTotal(res?.total);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setIsLoadingViewsItems(false);
    }
  }, [pageSize, pageNumber, filter, sortingOptions, dispatch]);

  useEffect(() => {
    loadViewsItems();
  }, [loadViewsItems, pageSize, pageNumber, filter, sortingOptions]);

  const loadStats = useCallback(async () => {
    if (!filter.selectedStoryId) return;

    setIsLoadingStats(true);

    try {
      const result = filter.selectedCampaignId
        ? await getStatsByCampaignId(
            filter.selectedStoryId,
            filter.selectedCampaignId,
            filter.dateRange
          )
        : await getStatsByStoryId(filter.selectedStoryId, filter.dateRange);

      setSessionStats(result);
    } catch (e) {
      console.log(e);
      dispatch(addNotification(DEFAULT_ERROR));
    } finally {
      setIsLoadingStats(false);
    }
  }, [filter, dispatch]);

  useEffect(() => {
    loadStats();
  }, [loadStats]);

  return {
    isLoadingViewsItems,
    viewsItems,
    total,
    filter,
    sortingOptions,
    pageNumber,
    pageSize,
    isLoadingCampaigns,
    campaigns,
    setCampaigns,
    isLoadingStats,
    sessionsStats,
    setPageNumber,
    setPageSize,
    setFilter,
    setSortingOptions,
  };
};
