import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useUserId } from './useUserId';
import { getSubscriptionUsageByUserId } from 'services/v2/subscriptions';
import { ISubscriptionSummary } from 'models';
import { addNotification } from 'store/actions/notification';
import { DEFAULT_ERROR } from 'constants/notifications';
import { LOADING_STATE } from 'constants/app';
import { makePromiseCancelable, TReturnMakePromiseCancelable } from 'services/service';

const defaultSummary = {
  story: { usedQuantity: 0, quantityToUse: 0 },
  email: { usedQuantity: 0, quantityToUse: 0 },
  sms: { usedQuantity: 0, quantityToUse: 0 },
} as ISubscriptionSummary;

let cachedData: ISubscriptionSummary | null = null;


export function useSubscriptionUsage(props?: { showStatistic?: boolean }) {
  const isStatisticNeeded = props?.showStatistic;

  const userId = useUserId();
  const dispatch = useDispatch();
  const [state, setState] = useState(LOADING_STATE.NOT_STARTED);
  const [summary, setSummary] = useState(cachedData || defaultSummary);
  let getCampaignsByStoryIdCancelable = useRef<null | TReturnMakePromiseCancelable>(null);


  const updateSummary = (sumObj: ISubscriptionSummary) => {
    const summary = Object.assign({ ...defaultSummary }, sumObj);
    cachedData = { ...summary };
    setSummary(summary);
  };

  const onErrorHandler = useCallback((error) => {
    console.error(error);

    setState(LOADING_STATE.REJECTED);
    dispatch(addNotification(DEFAULT_ERROR));
  }, [dispatch]);


  const loadSubscriptions = useCallback(async () => {
    setState(LOADING_STATE.PENDING);
    try {
      getCampaignsByStoryIdCancelable.current = makePromiseCancelable(
        getSubscriptionUsageByUserId({ userId, isStatisticNeeded })
      );
      const promiseRes = await getCampaignsByStoryIdCancelable.current;
      const response: ISubscriptionSummary = await promiseRes.promise;

      setState(LOADING_STATE.FULFILLED);
      updateSummary(response);

    } catch (err) {
      console.error('useCampaignReport', err);
      onErrorHandler(err);
    }

  }, [userId, isStatisticNeeded, onErrorHandler]);


  useEffect(() => {
    if (userId) {
      loadSubscriptions();
    }

    return () => {
      if (getCampaignsByStoryIdCancelable.current?.cancel) {
        getCampaignsByStoryIdCancelable.current.cancel()
      }
    };
  }, [userId, loadSubscriptions]);

  return {
    summary,
    isLoading: state === LOADING_STATE.PENDING,
    hasInitialized: state !== LOADING_STATE.NOT_STARTED && state !== LOADING_STATE.REJECTED
  };
}
