const REGION = 'us-east-1';
export const API_NAME = 'panelsadminapi';
const MEDIA_BUCKET = 'panels-media-files';

export default {
  Storage: {
    AWSS3: {
      bucket: MEDIA_BUCKET,
      region: REGION,
    },
  },
  Auth: {
    identityPoolId: 'us-east-1:73c0408d-c011-496e-869c-db0a8bbabe4d', // `admin panels ai`
    identityPoolRegion: REGION,
    userPoolId: 'us-east-1_XBkEWJrJA', // `Panels Users`
    userPoolWebClientId: '21c18ijfb2s5jhie1umr6ugd7u', // `Panels Admin Client`
    region: REGION,
    oauth: {
      domain: 'auth.panels.ai',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `${getDomainByEnv()}/federation-login?in`,
      redirectSignOut: `${getDomainByEnv()}/federation-login?out&login=1`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: API_NAME,
        endpoint: getApiDomain(),
      },
    ],
  },
  isProdEnv: isProdEnv(),
  features: {
    STOREFRONT: !isProdEnv(),
  }
};

export function isProdEnv() {
  return process.env.REACT_APP_ENV === 'PROD';
}

export function isDevelopmentEnv() {
  return process.env.REACT_APP_ENV === 'DEV' ||  process.env.REACT_APP_ENV === 'REMOTE';
}

export function isLocalClientEnv() {
  return process.env.REACT_APP_LOGIN === 'LOCAL';
}

function getApiDomain() {
  if (isProdEnv()) {
    return 'https://sw7h4timz5.execute-api.us-east-1.amazonaws.com/prod'
  }
  if (isDevelopmentEnv()) {
    return 'https://ynkwxpt80f.execute-api.us-east-1.amazonaws.com/dev'
  }

  return  'http://localhost:5000/dev';
}

function getDomainByEnv () {
  if (isProdEnv()) {
    return 'https://admin.panels.ai'
  }

  if (isDevelopmentEnv() && !isLocalClientEnv()) {
    return 'https://dev.admin.panels.ai';
  }

  return  'http://localhost:3000';
}